import React from 'react'
import { graphql } from 'gatsby'
import * as Component from '../components'

export default ({ data }) => {
  if (!data) return null

  return (
    <>
      <Component.Schema
        markup={{
          "name": "Hesam Bayat — Themes",
          "description": "TODO: Add page description",
          "url": "https://hesambayat.com/themes",
        }} />
      <Component.Header fixed />
      <div className="container">
        <div className="alignwide">
          <div className="section">
            <div className="row">
              {data.prismic.allThemes.edges.map(item => {
                return (
                  <div key={item.node._meta.uid} className="col-12 col-lg-6">
                    <Component.ThemeCard item={item} />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <Component.Footer fixed />
    </>
  )
}

export const query = graphql`
  query ThemesQuery {
    prismic {
      allThemes (sortBy: meta_lastPublicationDate_DESC) {
        edges {
          node {
            name
            title
            price
            discounted_price
            demo_url {
              ... on PRISMIC__ExternalLink{
                url
              }
            }
            buy_url {
              ... on PRISMIC__ExternalLink{
                url
              }
            }
            image
            imageSharp {
              childImageSharp {
                fixed(width: 500, height: 239) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            _meta {
              lastPublicationDate
              uid
            }
          }
        }
      }
    }
  }
`